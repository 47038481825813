import { NgModule } from '@angular/core';
import {
	AngularFireAuthGuard,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const redirectUnauthorizedToLogin = () =>
	redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['/app']);

const routes: Routes = [
	{
		path: '',
		redirectTo: 'auth/login',
		pathMatch: 'full',
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then(m => m.AuthPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToItems },
	},
	{
		path: 'app',
		loadChildren: () =>
			import('./pages/app/app.module').then(m => m.AppPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'logout',
		loadChildren: () =>
			import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
	},
 
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
